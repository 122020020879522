import dynamic from 'next/dynamic';

const Home = dynamic(() => import('@/Layout/Home'));

function Index() {
  return (
    <>
      <Home />
    </>
  );
}
Index.auth = false;
Index.meta = {
  title: '247torax #1 Escort Classified Site | Call Girl in India',
  description:
    'Meet with the high-profile call girls in your city. Find the call girl in Chennai, Bangalore, Hyderabad, Jaipur, Pune, Mumbai, Udaipur. POST YOUR AD absolutely FREE!',
  keyword:
    'Call girl Chennai, Call girl in Bangalore, Hyderabad call girl, Escort service Jaipur, Call girl Pune, Call girl in Mumbai, Udaipur call girl, Escort service Jaipur',
  siteUrl: 'https://247torax.com',
  locale: 'en_us',
  ogTitle: 'Adult Classified site in India - 247torax',
  ogType: 'website',
  ogUrl: 'https://247torax.com',
  siteName: '247torax',
  ogDescription:
    'Meet with the high-profile call girls in your city. Find the call girl in Chennai, Bangalore, Hyderabad, Jaipur, Pune, Mumbai, Udaipur. POST YOUR AD absolutely FREE!',
};
export default Index;
